const AppConfig = {
    localhost: {
        REGION: "eu-west-1",
        DOMAIN: "beta",
        USER_POOL_ID: "eu-west-1_U6yaT35hu",
        APP_CLIENT_ID: "3f675birmfk0ftv6dg89dp2gsg",
        EXCALIBUR_CLIENT_ID: "excalibur-dev",
        BRAAVOS_UI_LINK: "https://beta.braavos.fintech.amazon.dev/#/reports",
        BRAAVOS_OWNER_GROUP_ID: "6",
        OAUTH: {
            domain: "twin-website-beta-test.auth.eu-west-1.amazoncognito.com",
            scope: [
                "openid"
            ],
            redirectSignIn: "http://localhost:3000/",
            redirectSignOut: "http://localhost:3000/",
            responseType: "code",
        },
        API_GATEWAY_ENDPOINTS:{
            endpoints: [
                {
                    name: "TWIN_API",
                    endpoint: "https://rady8h8agj.execute-api.eu-west-1.amazonaws.com/beta"
                }
            ]
        },
        IDENTITY_POOL:{
            IDENTITY_POOL_ID: "eu-west-1:9c9c5dea-239c-4c6f-9934-cc831bc0d06c",
            IDENTITY_POOL_REGION: "eu-west-1"
        },
        EXCALIBUR_BUCKET:"beta-taskless-excalibur-generated-reports",
        FILING_BUCKET:"beta-taskless-client-e-filer",
        CERTIFICATE_BUCKET_NAME: "beta-taskless-efiler-certificates",
        FILING_RECEIPT_BUCKET:"beta-taskless-excalibur-filing-receipt",
        ARTIFACTS_BUCKET:"beta-taskless-e-filer-client-artifacts",
        UK_FILING_REDIRECT_URI: "https://test-www.tax.service.gov.uk/oauth/authorize?response_type=code&client_id=MSuW4LtrC1j1KU7kHKorwXXwxQaE&scope=read:vat+write:vat&redirect_uri=",
        MOG_SHIELD_DTREE_URL: 'https://dtreewebsite-na.corp.amazon.com/registry-detail?registrationID=8874b04a-98ef-4eb8-9dcf-bb2a828cb83e&clientID=SHIELD',
        AG_GRID_LICENSE : ''
    },
    beta: {
        REGION: "eu-west-1",
        DOMAIN: "beta",
        USER_POOL_ID: "eu-west-1_U6yaT35hu",
        APP_CLIENT_ID: "3f675birmfk0ftv6dg89dp2gsg",
        EXCALIBUR_CLIENT_ID: "excalibur-dev",
        BRAAVOS_UI_LINK: "https://beta.braavos.fintech.amazon.dev/#/reports",
        BRAAVOS_OWNER_GROUP_ID: "6",
        OAUTH: {
            domain: "twin-website-beta-test.auth.eu-west-1.amazoncognito.com",
            scope: [
                "openid"
            ],
            redirectSignIn: "https://beta.phastos.tax.amazon.dev/",
            redirectSignOut: "https://beta.phastos.tax.amazon.dev/",
            responseType: "code",
        },
        API_GATEWAY_ENDPOINTS:{
            endpoints: [
                {
                    name: "TWIN_API",
                    endpoint: "https://rady8h8agj.execute-api.eu-west-1.amazonaws.com/beta"
                }
            ]
        },
        IDENTITY_POOL:{
            IDENTITY_POOL_ID: "eu-west-1:9c9c5dea-239c-4c6f-9934-cc831bc0d06c",
            IDENTITY_POOL_REGION: "eu-west-1"
        },
        EXCALIBUR_BUCKET:"beta-taskless-excalibur-generated-reports",
        FILING_BUCKET:"beta-taskless-client-e-filer",
        CERTIFICATE_BUCKET_NAME: "beta-taskless-efiler-certificates",
        FILING_RECEIPT_BUCKET:"beta-taskless-excalibur-filing-receipt",
        ARTIFACTS_BUCKET:"beta-taskless-e-filer-client-artifacts",
        UK_FILING_REDIRECT_URI: "https://test-www.tax.service.gov.uk/oauth/authorize?response_type=code&client_id=MSuW4LtrC1j1KU7kHKorwXXwxQaE&scope=read:vat+write:vat&redirect_uri=",
        MOG_SHIELD_DTREE_URL: 'https://dtreewebsite-na.corp.amazon.com/registry-detail?registrationID=8874b04a-98ef-4eb8-9dcf-bb2a828cb83e&clientID=SHIELD',
        AG_GRID_LICENSE : ''
    },
    gamma: {
        REGION: "eu-west-1",
        DOMAIN: "gamma",
        USER_POOL_ID: "eu-west-1_1Mw1Bafw4",
        APP_CLIENT_ID: "nna0g2or8s411a0oo62v7eel0",
        EXCALIBUR_CLIENT_ID: "excalibur-master",
        BRAAVOS_UI_LINK: "https://gamma.braavos.fintech.amazon.dev/#/reports",
        BRAAVOS_OWNER_GROUP_ID: "4",
        OAUTH: {
            domain: "twin-ui-gamma.auth.eu-west-1.amazoncognito.com",
            scope: [
                "openid"
            ],
            redirectSignIn: "https://gamma.phastos.tax.amazon.dev/",
            redirectSignOut: "https://gamma.phastos.tax.amazon.dev/",
            responseType: "code",
        },
        API_GATEWAY_ENDPOINTS:{
            endpoints: [
                {
                    name: "TWIN_API",
                    endpoint: "https://3xmnohx4jj.execute-api.eu-west-1.amazonaws.com/gamma"
                }
            ]
        },
        IDENTITY_POOL:{
            IDENTITY_POOL_ID: "eu-west-1:63dc819e-e750-42d0-818e-e100ef2001ee",
            IDENTITY_POOL_REGION: "eu-west-1"
        },
        EXCALIBUR_BUCKET:"gamma-taskless-excalibur-generated-reports",
        FILING_BUCKET:"gamma-taskless-client-e-filer",
        CERTIFICATE_BUCKET_NAME: "gamma-taskless-efiler-certificates",
        FILING_RECEIPT_BUCKET:"gamma-taskless-excalibur-filing-receipt",
        ARTIFACTS_BUCKET:"gamma-taskless-e-filer-client-artifacts",
        UK_FILING_REDIRECT_URI: "https://test-www.tax.service.gov.uk/oauth/authorize?response_type=code&client_id=MSuW4LtrC1j1KU7kHKorwXXwxQaE&scope=read:vat+write:vat&redirect_uri=",
        MOG_SHIELD_DTREE_URL: 'https://dtreewebsite-gamma-eu-dub.dub.proxy.amazon.com/registry-detail?registrationID=8874b04a-98ef-4eb8-9dcf-bb2a828cb83e&clientID=SHIELD',
        AG_GRID_LICENSE : ''
    },
    prod: {
        REGION: "eu-west-1",
        DOMAIN: "prod",
        USER_POOL_ID: "eu-west-1_rkw13St7G",
        APP_CLIENT_ID: "5tpsvamberat7h95r0587qfikk",
        EXCALIBUR_CLIENT_ID: "excalibur-prod",
        BRAAVOS_UI_LINK: "https://braavos.fintech.amazon.dev/#/",
        BRAAVOS_OWNER_GROUP_ID: "4",
        OAUTH: {
            domain: "twin-ui-prod.auth.eu-west-1.amazoncognito.com",
            scope: [
                "openid"
            ],
            redirectSignIn: "https://prod.phastos.tax.amazon.dev/",
            redirectSignOut: "https://prod.phastos.tax.amazon.dev/",
            responseType: "code",
        },
        API_GATEWAY_ENDPOINTS:{
            endpoints: [
                {
                    name: "TWIN_API",
                    endpoint: "https://ncb57eb3yc.execute-api.eu-west-1.amazonaws.com/prod"
                }
            ]
        },
        IDENTITY_POOL:{
            IDENTITY_POOL_ID: "eu-west-1:0b35ea53-c1ea-48fd-8aa1-bbf95bac5347",
            IDENTITY_POOL_REGION: "eu-west-1"
        },
        EXCALIBUR_BUCKET:"prod-taskless-excalibur-generated-reports",
        FILING_BUCKET:"prod-taskless-client-e-filer",
        CERTIFICATE_BUCKET_NAME: "prod-taskless-efiler-certificates",
        FILING_RECEIPT_BUCKET:"prod-taskless-excalibur-filing-receipt",
        ARTIFACTS_BUCKET:"prod-taskless-e-filer-client-artifacts",
        UK_FILING_REDIRECT_URI: "https://www.tax.service.gov.uk/oauth/authorize?response_type=code&client_id=Ie4p9fFyRPpenRwh1Xpsm08oC1zJ&scope=read:vat+write:vat&redirect_uri=",
        MOG_SHIELD_DTREE_URL: 'https://dtreewebsite-na-iad.iad.proxy.amazon.com/registry-detail?registrationID=8874b04a-98ef-4eb8-9dcf-bb2a828cb83e&clientID=SHIELD',
        AG_GRID_LICENSE : 'Using_this_{AG_Grid}_Enterprise_key_{AG-071974}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Amazon_(USA,_NY,_10019)}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Taskless-TWIN}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Taskless-TWIN}_need_to_be_licensed___{Taskless-TWIN}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{27_November_2025}____[v3]_[01]_MTc2NDIwMTYwMDAwMA==d8d7ab63c096dbb58c6cac85af3c03ac'
    }
};

export default AppConfig;